"use strict";

document.addEventListener('DOMContentLoaded', () => {
    const keys = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    const ignoredKeys = ['Shift', 'Control', 'Alt', 'Meta', 'CapsLock', 'Tab', 'Escape'];
    const symbols = ['⮝', '⮝', '⮟', '⮟', '⮜', '⮞', '⮜', '⮞', 'Ⓑ', 'Ⓐ'];
    
    const konamiContainer = document.createElement('div');
    konamiContainer.classList.add('eegg');
    
    const elements = symbols.map((symbol, i) => {
        const element = document.createElement('span');
        element.textContent = symbol;
        konamiContainer.appendChild(element);
        return element;
    });
    
    let codePosition = 0;
    
    // Resets the Konami code, deactivating any activated keys and reverting the code position to index zero.
    function reset() {
        while (codePosition > 0) {
            codePosition--;
            elements[codePosition].classList.remove('active');
        }
    }
    
    // Promise will be fulfilled once audio and lyrics are available; promise itself will be null until
    // the first time the easter egg is triggered.
    let promise = null;
    
    // Start the Easter Egg, plays the song, and then reverts to the stopped state.
    function start() {
        // Disable key handling while the easter egg is active.
        document.removeEventListener('keydown', keyDownHandler);
        
        // If promise is null, then loading the assets hasn't started yet, do that now.
        if (promise === null) {
            promise = Promise.all([
                new Promise((resolve, reject) => {
                    const song = new Audio('/assets/eegg.ogg');
                    song.addEventListener('canplaythrough', () => resolve(song));
                    song.addEventListener('error', reject);
                }),
                fetch('/assets/eegg.json').then((response) => response.json())
            ]);
        }
        
        // Once the assets are loaded, start playing the audio and rendering the lyrics,
        // and once both are done, put the easter egg back into the stopped state.
        promise.then(([song, lyrics]) => Promise.all([
            new Promise((resolve) => {
                const ended = () => {
                    song.removeEventListener('ended', ended);
                    resolve();
                };

                song.addEventListener('ended', ended);
                song.play();
            }),
            renderLyrics(lyrics)
        ])).then(stop);
    }
    
    // Put the Easter Egg into the stopped state, where it waits for the Konami code keys to be pressed to trigger.
    function stop () {
        reset();
        document.addEventListener('keydown', keyDownHandler);
    }
    
    function keyDownHandler(e) {
        if (e.key.toLowerCase() !== keys[codePosition].toLowerCase()) {
            if (!ignoredKeys.includes(e.key)) {
                reset();
            }
            return;
        }
        
        elements[codePosition].classList.add('active');
        codePosition++;
        
        if (codePosition === keys.length) {
            start();
        }
    }

    function renderLyrics(lyrics) {
        const fadeInTime = 0.3;
        const fadeOutTime = 0.3;

        const lyricsContainer = document.createElement('div');
        lyricsContainer.classList.add('eegg-lyrics');
        document.body.insertBefore(lyricsContainer, konamiContainer);

        // Create a promise for each line, and remove the lyrics container once they've all been resolved.
        return Promise.all(lyrics.map(({start, end, text}) => {
            // figure out when exactly everything should be happening.
            const startTS = Math.min(fadeInTime, start);
            const stopTS = startTS + (end - start);
            const removeTS = stopTS + fadeOutTime;

            return new Promise((resolve) => {
                // Begin by setting a timeout for the time when the line should start.
                setTimeout(resolve, Math.max(0, start - fadeInTime) * 1000);
            })
            .then(() => new Promise((resolve) => {
                // Create the line element, define the keyframes to animate it, and resolve the promise
                // once the animation is finished.
                const line = document.createElement('div');
                line.textContent = text;
                lyricsContainer.appendChild(line);

                line.animate([{ 
                    transform: 'scale(1.5)',
                    opacity: 0,
                }, {
                    transform: 'scale(1)',
                    opacity: 1,
                    offset: startTS/removeTS,
                    easing: 'ease-in'
                }, {
                    transform: 'scale(1)',
                    opacity: 1,
                    offset: stopTS/removeTS,
                }, {
                    transform: 'scale(0.5)',
                    opacity: 0,
                    easing: 'ease-out'
                }], removeTS * 1000).finished.then(() => {
                    lyricsContainer.removeChild(line);
                    resolve();
                })
            }));
        })).then(() => document.body.removeChild(lyricsContainer));
    }
    
    // Initialize the Easter Egg to be in the stopped state.
    document.body.appendChild(konamiContainer);
    stop();
});
